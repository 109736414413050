<template>
  <div class="snp-form">
    <form @submit.prevent="onFormSubmit">
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.general-data") }}</h3>
        </div>
        <vs-input
          :label-placeholder="$l.get('snp-form.rsid-field', 'capitalize')"
          :danger="isRsidInvalid"
          :danger-text="$l.get('errors.invalid-snp-rsid', 'capitalize')"
          v-model="snp.rsid"
        />
        <vs-input
          :label-placeholder="$l.get('snp-form.chromosome-field', 'capitalize')"
          :danger="isChromosomeInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="snp.chromosome"
        />
        <vs-input
          :label-placeholder="$l.get('snp-form.position-field', 'capitalize')"
          :danger="isPositionInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="snp.position"
        />
        <br />
        <div>
          <vs-textarea
            :label="$l.get('snp-form.about-pt-br-text-field')"
            v-model="snp.aboutPtBrText"
          />
        </div>
        <div>
          <vs-textarea
            :label="$l.get('snp-form.about-en-us-text-field')"
            v-model="snp.aboutEnUsText"
          />
        </div>
        <div>
          <vs-textarea
            :label="$l.get('snp-form.about-es-es-text-field')"
            v-model="snp.aboutEsEsText"
          />
        </div>
      </vs-card>
      <input
        type="submit"
        style="position: absolute; left: -9999px; width: 1px; height: 1px;"
        tabindex="-1"
      />
    </form>
    <div style="margin-top: 25px; margin-bottom: 25px; text-align: right">
      <vs-button color="primary" type="filled" @click="onFormSubmit()">{{
        $l.get("dictionary.submit-new")
      }}</vs-button>
    </div>
  </div>
</template>

<script>
const RE_RSID = /^([Ii]|[Rr][Ss])\d+$/i;

export default {
  name: "snp-form",
  props: ["snp", "onSubmit"],
  data() {
    return {
      isRsidInvalid: false,
      isChromosomeInvalid: false,
      isPositionInvalid: false
    };
  },
  mounted() {},
  methods: {
    onFormSubmit() {
      this.isRsidInvalid = !this.snp.rsid.match(RE_RSID);
      this.isChromosomeInvalid = this.snp.chromosome.length == 0;
      this.isPositionInvalid = this.snp.position.length == 0;
      if (
        this.isRsidInvalid ||
        this.isChromosomeInvalid ||
        this.isPositionInvalid
      ) {
        return;
      } else {
        this.snp.aboutPtBrHtml = this.snp.aboutPtBrText
          .split("\n")
          .reduce((e, v) => `${e}<p>${v}</p>`, "");
        this.snp.aboutEnUsHtml = this.snp.aboutEnUsText
          .split("\n")
          .reduce((e, v) => `${e}<p>${v}</p>`, "");
        this.snp.aboutEsEsHtml = this.snp.aboutEsEsText
          .split("\n")
          .reduce((e, v) => `${e}<p>${v}</p>`, "");
        this.onSubmit();
      }
    }
  }
};
</script>

<style lang="scss">
.snp-form .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}

.snp-form .criteria-node {
  cursor: pointer;
  padding: 0 2px;
  min-width: 60px;
  text-align: center;
  font-size: 11px;
  height: 30px;
  border: 2px solid #e8e8e3;
  border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: #fff;
}

.snp-form .criteria-node:active {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  margin: 1px 0 0 1px;
  border: 2px solid #d3d3cb;
}

.snp-form .criteria-node .node-name {
  line-height: 30px;
  color: #9b9b9b;
}

.snp-form .criteria-node:hover .node-name {
  color: #8b8b8b;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
}

// .snp-form .vs-input {
//   margin: 15px;
// }
</style>
