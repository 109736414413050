<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">{{ $l.get("snp-edit.header") }}</h3>
      <SnpForm :snp="data.snp" :onSubmit="onSubmit" v-if="data && data.snp" />
    </div>
  </div>
</template>

<script>
import SubmitFormInteractor from "@/business/snp-edit/submit-form";
import InitSnpEditInteractor from "@/business/snp-edit/init-snp-edit";
import SnpEditScreenController from "@/adapters/controllers/screen-snp-edit";
import SnpForm from "@/application/components/dna/SnpForm";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "snp-edit",
  components: { Loader, SnpForm },
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initSnpEdit: null
      },
      data: null,
      isLoading: false
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(SnpEditScreenController);

    //{ INTERACTORS
    this.interactors.initSnpEdit = this.$injector.get(InitSnpEditInteractor);
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initSnpEdit.handle(id);
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.interactors.submitForm.handle(this.data.snp);
    }
  }
};
</script>

<style lang="scss"></style>
